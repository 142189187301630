export default class SectionGrid {

    // Constructor
    constructor() {
        // All sections that should have the animation
        this.sections = null;

        // Avoid window to overload with the event trigger
        this.ticking = false;

        // When all the section were animated
        this.done = false;
    }

    // Init before frameworks
    init() {
        // All sections that should have the animation
        this.sections = document.querySelectorAll('.tps-section-has-animation');

        if (this.sections) {
            // Add "animated" class to a section on scroll
            this.addEffectOnScroll();
		}
    }

    // Init after frameworks
    initAfterFrameworks() {}

    // Add "animated" class to a section on scroll
    addEffectOnScroll() {
        const self = this;

        // On event on scroll
        window.addEventListener('scroll', e => {
            if (!self.done && !self.ticking) {
                window.requestAnimationFrame(function () {
                    // Add or remove "animated" class based on scroll position
                    self._applyAnimatedClass(window.scrollY, window.innerHeight);

                    // Avoid window to overload with the event trigger
                    self.ticking = false;
                });

                // Avoid window to overload with the event trigger
                self.ticking = true;
            }
        });

        // Add or remove "animated" class based on scroll position
        self._applyAnimatedClass(window.scrollY, window.innerHeight);
    }

    // Helper: add or remove "animated" class based on scroll position
    _applyAnimatedClass(scrollY, windowHeight) {
        // Sections that still don't have the "animated" class
        const sections = [...this.sections].filter(section => section.classList.contains('tps-section-animated') === false);

        if (sections.length > 0) {
            // Middle of the window (vertical)
            const middleOfWindow = scrollY + (windowHeight / 2);

            sections.forEach((section) => {
                const offsetTop = section.getBoundingClientRect().top + window.scrollY;

                // If the top of the section is positioned ahead of the middle of the window
                if (offsetTop < middleOfWindow) {
                    // Add class that triggers the animation
                    section.classList.add('tps-section-animated');
                }
            });
        } else {
            // When all the section were animated
            this.done = true;
        }
    }

}
