export default class Video {

    // Constructor
    constructor() {
        this.tpsVideo = null;
        this.playPauseButton = null;
    }

    // Init before frameworks
    init() {
        this.tpsVideo = document.querySelectorAll('.tps-video');
        this.playPauseButton = document.querySelectorAll('.tps-widget-video-control');

        if (this.tpsVideo) {
            this.togglePlayPauseButton();
        }
    }

    // Init after frameworks
    // initAfterFrameworks() { }


    // Listening Play/Pause Button
    togglePlayPauseButton() {        
        const _playPauseButton = this.playPauseButton;

        _playPauseButton.forEach(function (video) {
            video.addEventListener('click', () => {                
                video.classList.toggle('playing');
                if (video.classList.contains('playing')) {
                    video.previousElementSibling.play();
                }
                else {
                    video.previousElementSibling.pause();
                }
            });
        });
    }

}
