// Imports
import Browser from './Browser';
import Polyfill from './Polyfill';
import LazyLoadWrapper from './LazyLoadWrapper';
import Header from './Header';
import MainNav from './MainNav';
import Button from './Button';
import SectionGrid from './SectionGrid';
import OurTeam from './OurTeam';
import HorizontalSlider from './HorizontalSlider';
import ContactUs from './ContactUs';
import JobApplication from './JobApplication';
import AwardsCounters from './AwardsCounters';
import Video from './Video';
import Services from './Services';

export default class TPS {

    // Constructor
    constructor() {
        // All classes instances
        this.browser = new Browser();
        this.polyfill = new Polyfill();
        this.lazyLoadWrapper = new LazyLoadWrapper();
        this.header = new Header();
        this.mainNav = new MainNav();
        this.button = new Button();
        this.sectionGrid = new SectionGrid();
        this.ourTeam = new OurTeam();
        this.horizontalSlider = new HorizontalSlider();
        this.contactUs = new ContactUs();
        this.jobApplication = new JobApplication();
        this.awardsCounters = new AwardsCounters();
        this.video = new Video();
        this.services = new Services();
    }

    // On DOM ready
    onDomReady() {
        const self = this;

        // On DOM ready
        document.addEventListener('DOMContentLoaded', (e) => {
            if (document.readyState === 'interactive' || document.readyState === 'complete') {
                self.onDomReadyCallback();
            }
        });
    }

    // On DOM ready (callback)
    onDomReadyCallback() {
        // Init before frameworks
        this.init();

        // Foundation 6 initialization
        jQuery(document).foundation();

        // Init after frameworks
        this.initAfterFrameworks();
    }

    // Init before frameworks
    init() {
        for (var i in this) {
            if (typeof this[i].init === 'function') {
                this[i].init();
            }
        }
    }

    // Init after frameworks
    initAfterFrameworks() {
        for (var i in this) {
            if (typeof this[i].initAfterFrameworks === 'function') {
                this[i].initAfterFrameworks();
            }
        }
    }

}
