export default class LazyLoadWrapper {

    // Constructor
    constructor() {
        this.images = null;
        this.lazyload = null;
    }

    // Init before frameworks
    init() {
        const _this = this;

        _this.images = document.querySelectorAll('img.lazy');

        if (_this.images) {
            _this.lazyload = lazyload(this.images);
            // force inline images to be loaded
            // when print is called from browser
            window.addEventListener("beforeprint", function (e) {
                _this.lazyload.loadImages();
            });

        }
    }

    // Init after frameworks
    initAfterFrameworks() { }
}
