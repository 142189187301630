export default class ContactUs {

    // Constructor
    constructor() {
        this.formSelector = '.tps-widget-contactus';
        this.submitButtonSelector = '.tps-widget-contactus + input[type="submit"]';
        this.submitButtonPlaceholderSelector = '.tps-widget-contactus-submit-button-placeholder';
    }

    // Init before frameworks
    init() { }

    // Init after frameworks
    initAfterFrameworks() { }

    // Change submit button position
    changeSubmitButtonPosition() {
        this.form = document.querySelector(this.formSelector);
        this.submitButton = document.querySelector(this.submitButtonSelector);
        this.submitButtonPlaceholder = document.querySelector(this.submitButtonPlaceholderSelector);

        if (this.form && this.submitButton && this.submitButtonPlaceholder) {
            this.submitButtonPlaceholder.appendChild(this.submitButton);
        }
    }

}
