export default class Button {

    // Constructor
    constructor() {
        this.buttonsSelector = '.button:not(.secondary):not(.cta):not(.basic):not(.nav)';
    }

    // Init before frameworks
    init() {
        this.buttons = document.querySelectorAll(this.buttonsSelector);

        if (this.buttons) {
            setTimeout(function () {
                window.tps.button.addDots();
            }, 300);

            this.onButtonHover();
        }
    }

    // Init after frameworks
    initAfterFrameworks() { }

    // Add underline dots to the desktop main nav items (hover effect)
    addDots() {
        const dotWidth = 10; // px
        const dotDisccount = 6; // 3 px each side
        const dotsHtml = `<span class="tps-button-dots"></span>`;
        const dotHtml = `<span class="tps-button-dot"></span>`;

        [...this.buttons].forEach((button) => {
            const buttonWidth = button.clientWidth + dotDisccount;
            const dotsToAdd = Math.floor(buttonWidth / dotWidth);

            button.insertAdjacentHTML('beforeend', dotsHtml);
            const dots = button.querySelector('.tps-button-dots');

            let i = 0;

            while (i < dotsToAdd) {
                dots.insertAdjacentHTML('beforeend', dotHtml);
                i++;
            }
        });
    }

    // On desktop main nav items mouse over/out
    onButtonHover() {
        [...this.buttons].forEach(button => button.addEventListener('mouseover', () => {
            button.classList.add('mouseover');
            button.classList.remove('mouseout');
        }));

        [...this.buttons].forEach(button => button.addEventListener('mouseout', () => {
            button.classList.add('mouseout');
            button.classList.remove('mouseover');
        }));
    }

}
