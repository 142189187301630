export default class OurTeam {

    // Constructor
    constructor() {
        this.galleryItemButtonSelector = '.tps-widget-ourteam-gallery-item-button';
        this.galleryItemBackgroundSelector = '.tps-widget-ourteam-gallery-item-bg';

        this.galleryItemButtons = null;
        this.galleryItemBackgrounds = null;
    }

    // Init before frameworks
    init() {
        this.galleryItemButtons = document.querySelectorAll(this.galleryItemButtonSelector);
        this.galleryItemBackgrounds = document.querySelectorAll(this.galleryItemBackgroundSelector);

        if (this.galleryItemButtons) {
            this.onGalleryItemButtonClick();
        }

        if (this.galleryItemBackgrounds) {
            this.onGalleryItemBackgroundClick();
        }
    }

    // Init after frameworks
    initAfterFrameworks() { }

    onGalleryItemButtonClick() {
        [...this.galleryItemButtons].forEach(button => button.addEventListener('click', () => {
            button.parentNode.classList.toggle('active');
            [...this.galleryItemButtons].filter(_button => _button !== button).filter(_button => _button.parentNode.classList.contains('active')).forEach(_button => _button.parentNode.classList.remove('active'));
        }));
    }

    onGalleryItemBackgroundClick() {
        [...this.galleryItemBackgrounds].forEach(bg => bg.addEventListener('click', () => {
            if (!Foundation.MediaQuery.is('large')) {
                bg.parentNode.classList.toggle('active');
                [...this.galleryItemBackgrounds].filter(_bg => _bg !== bg).filter(_bg => _bg.parentNode.classList.contains('active')).forEach(_bg => _bg.parentNode.classList.remove('active'));
            }
        }));
    }
}
