export default class MainNav {

    // Constructor
    constructor() {
        this.itemsSelector = '.tps-nav-main-item';
        this.itemsLinkSelector = '.tps-nav-main-link';

        this.mobileItemsSelector = '.tps-nav-main-mobile-item';
        this.mobileItemsLinkSelector = '.tps-nav-main-mobile-link';
    }

    // Init before frameworks
    init() {
        this.items = document.querySelectorAll(this.itemsSelector);
        this.itemsLinks = document.querySelectorAll(this.itemsLinkSelector);

        this.mobileItems = document.querySelectorAll(this.mobileItemsSelector);
        this.mobileItemsLinks = document.querySelectorAll(this.mobileItemsLinkSelector);

        if (this.items) {
            this.addDots();
        }

        if (this.itemsLinks) {
            this.onItemHover();
        }

        if (this.mobileItems) {
            this.addMobileDots();
        }

        if (this.mobileItemsLinks) {
            this.onMobileItemHover();
        }
    }

    // Init after frameworks
    initAfterFrameworks() { }

    // Add underline dots to the desktop main nav items (hover effect)
    addDots() {
        const dotWidth = 10; // px
        const dotsHtml = `<span class="tps-nav-main-dots"></span>`;
        const dotHtml = `<span class="tps-nav-main-dot"></span>`;

        [...this.items].forEach((item) => {
            const itemWidth = item.clientWidth;
            const dotsToAdd = Math.round(itemWidth / dotWidth);

            item.insertAdjacentHTML('beforeend', dotsHtml);
            const dots = item.querySelector('.tps-nav-main-dots');

            let i = 0;

            while (i < dotsToAdd) {
                dots.insertAdjacentHTML('beforeend', dotHtml);
                i++;
            }
        });
    }

    // Add underline dots to the mobile main nav items (hover effect)
    addMobileDots() {
        const dotsHtml = `<span class="tps-nav-main-mobile-dots"></span>`;
        const dotHtml = `<span class="tps-nav-main-mobile-dot"></span>`;
        const dotsToAdd = 6;

        [...this.mobileItems].forEach((item) => {
            item.insertAdjacentHTML('beforeend', dotsHtml);
            const dots = item.querySelector('.tps-nav-main-mobile-dots');

            let i = 0;

            while (i < dotsToAdd) {
                dots.insertAdjacentHTML('beforeend', dotHtml);
                i++;
            }
        });
    }

    // On desktop main nav items mouse over/out
    onItemHover() {
        [...this.itemsLinks].forEach(itemLink => itemLink.addEventListener('mouseover', () => {
            if (!itemLink.parentNode.classList.contains('active')) {
                itemLink.classList.add('mouseover');
                itemLink.classList.remove('mouseout');
            }
        }));

        [...this.itemsLinks].forEach(itemLink => itemLink.addEventListener('mouseout', () => {
            if (!itemLink.parentNode.classList.contains('active')) {
                itemLink.classList.add('mouseout');
                itemLink.classList.remove('mouseover');
            }
        }));
    }

    // On mobile main nav items mouse over/out
    onMobileItemHover() {
        [...this.mobileItemsLinks].forEach(itemLink => itemLink.addEventListener('mouseover', () => {
            if (!itemLink.parentNode.classList.contains('active')) {
                itemLink.classList.add('mouseover');
                itemLink.classList.remove('mouseout');
            }
        }));

        [...this.mobileItemsLinks].forEach(itemLink => itemLink.addEventListener('mouseout', () => {
            if (!itemLink.parentNode.classList.contains('active')) {
                itemLink.classList.add('mouseout');
                itemLink.classList.remove('mouseover');
            }
        }));
    }

}
