export default class JobApplication {

    // Constructor
    constructor() {
        this.formSelector = '.tps-widget-jobapplication';
        this.submitButtonSelector = '.tps-widget-jobapplication + input[type="submit"]';
        this.submitButtonPlaceholderSelector = '.tps-widget-jobapplication-submit-button-placeholder';

        this.jobTitleFieldSelector = '.tps-widget-jobapplication input[name*="JobTitle"]';
        this.jobTitleTextSelector = '.tps-widget-jobdetail-title';
    }

    // Init before frameworks
    init() {
        this.jobTitleField = document.querySelector(this.jobTitleFieldSelector);
        this.jobTitleText = document.querySelector(this.jobTitleTextSelector);

        if (this.jobTitleField && this.jobTitleText) {
            this.setJobTitle();
        }
    }

    // Init after frameworks
    initAfterFrameworks() { }

    // Set job title
    setJobTitle() {
        this.jobTitleField.value = this.jobTitleText.innerHTML;
    }

    // Change submit button position
    changeSubmitButtonPosition() {
        this.form = document.querySelector(this.formSelector);
        this.submitButton = document.querySelector(this.submitButtonSelector);
        this.submitButtonPlaceholder = document.querySelector(this.submitButtonPlaceholderSelector);

        if (this.form && this.submitButton && this.submitButtonPlaceholder) {
            this.submitButtonPlaceholder.appendChild(this.submitButton);
        }
    }

}
