export default class AwardsCounters {

    // Constructor
    constructor() {
        // All widgets
        this.widgets = null;

        // Avoid window to overload with the event trigger
        this.ticking = false;

        // When all the widgets were animated
        this.done = false;
    }

    // Init before frameworks
    init() { }

    // Init after frameworks
    initAfterFrameworks() {

        // All widgets
        this.widgets = document.querySelectorAll('.tps-widget-awardscounters');

        if (this.widgets) {
            // animate the counters for LARGE+ (1024) breakpoints
            if (Foundation.MediaQuery.atLeast('large')) {
                // Set counters effect based on scroll position
                this.addEffectOnScroll();

            } else {

                this.setCountersOnLoad();

            }
        }

    }

    // Set counters effect based on scroll position
    addEffectOnScroll() {
        const self = this;

        // On event on scroll
        window.addEventListener('scroll', e => {
            if (!self.done && !self.ticking) {
                window.requestAnimationFrame(function () {
                    // Add or remove "animated" class based on scroll position
                    self._applyAnimatedClass(window.scrollY, window.innerHeight);

                    // Avoid window to overload with the event trigger
                    self.ticking = false;
                });

                // Avoid window to overload with the event trigger
                self.ticking = true;
            }
        });

        // Add or remove "animated" class based on scroll position
        self._applyAnimatedClass(window.scrollY, window.innerHeight);
    }

    // Set the counter value without animation on page load
    setCountersOnLoad() {
        const self = this;

        // Widgets that still don't have the "animated" class
        const widgets = [...this.widgets].filter(widget => widget.classList.contains('tps-widget-counter-animated') === false);


        widgets.forEach((widget) => {

            widget.classList.add('tps-widget-counter-animated');

            const counters = widget.querySelectorAll('.tps-widget-awardscounters-number-number');

            if (counters) {
                [...counters].forEach(counter => {
                    
                    const original = counter.dataset.counterOriginal;
                    counter.innerText = original;

                });
            }

        });


    }

    // Helper: add or remove "animated" class based on scroll position
    _applyAnimatedClass(scrollY, windowHeight) {
        const self = this;

        // Widgets that still don't have the "animated" class
        const widgets = [...this.widgets].filter(widget => widget.classList.contains('tps-widget-counter-animated') === false);

        if (widgets.length > 0) {
            // Middle of the window (vertical)
            const middleOfWindow = scrollY + (windowHeight / 2);

            widgets.forEach((widget) => {
                const offsetTop = widget.closest('.tps-section').getBoundingClientRect().top + window.scrollY;

                // If the top of the widget is positioned ahead of the middle of the window
                if (offsetTop < middleOfWindow) {
                    // Add class that triggers the animation
                    widget.classList.add('tps-widget-counter-animated');

                    // Set counters effect
                    self._setCountersEffect(widget);
                }
            });
        } else {
            // When all the widgets were animated
            this.done = true;
        }
    }

    // Set counters effect
    _setCountersEffect(widget) {
        const self = this;

        // All counters from the current widget
        const counters = widget.querySelectorAll('.tps-widget-awardscounters-number-number');

        if (counters) {
            [...counters].forEach(counter => {
                counter.dataset.timer = self._setTimeout(counter);
            });
        }
    }

    // Helper: set timer
    _setTimeout(counter) {
        const self = this;

        if (counter.dataset.counterMax !== ''
            && counter.dataset.counterStep !== ''
            && counter.dataset.counterSpeed !== '') {

            const speed = Number.parseInt(counter.dataset.counterSpeed);

            counter.dataset.timer = setTimeout(function () {
                self._increaseCounter(counter);
            }, speed);
        } else {
            self._increaseCounter(counter);
        }
    }

    // Helper: increase counter
    _increaseCounter(counter) {
        const self = this;

        if (counter.dataset.counterMax !== ''
            && counter.dataset.counterStep !== ''
            && counter.dataset.counterSpeed !== '') {

            const max = Number.parseInt(counter.dataset.counterMax);
            const step = Number.parseInt(counter.dataset.counterStep);
            const prev = Number.parseInt(counter.innerText);

            if (prev < max) {
                let next = prev + step;

                if (next > max) {
                    next = max;
                }

                counter.innerText = next;
                counter.dataset.timer = self._setTimeout(counter);
            } else {
                const original = counter.dataset.counterOriginal;
                counter.innerText = original;
            }
        } else {
            const original = counter.dataset.counterOriginal;
            counter.innerText = original;
        }

    }

}
