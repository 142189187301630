export default class HorizontalSlider {

    // Constructor
    constructor() {
        // Titles
        this.titles = null;

        // Animate on scroll...

        // All widgets that should have the animation
        this.widgets = null;

        // Avoid window to overload with the event trigger
        this.ticking = false;

        // When all the widgets were animated
        this.done = false;
    }

    // Init before frameworks
    init() {
        // Titles
        this.titles = document.querySelectorAll('.tps-widget-hslider-slide-title');

        if (this.titles) {
            // On title click
            this.onTitleClick();
        }

        // Animate on scroll...

        // Widgets
        this.widgets = document.querySelectorAll('.tps-widget-hslider');

        if (this.widgets) {
            // Add "animated" class to a widget on scroll
            this.addEffectOnScroll();
        }
    }

    // Init after frameworks
    initAfterFrameworks() { }

    // On title click
    onTitleClick() {
        const self = this;

        // On title click
        [...this.titles].forEach(title => title.addEventListener('click', (e) => {
            e.preventDefault();

            // Next active slide
            const nextSlide = title.parentNode;

            // Current active slide
            const currentSlide = [...self.titles].filter(_title => _title.parentNode.classList.contains('active'))[0].parentNode;

            // Do not execute this code if the current slide is the active one
            if (nextSlide === currentSlide) {
                return;
            }

            // ...

            // Preserve height (only works for desktop, not mobile)
            const currentHeight = nextSlide.offsetHeight;
            nextSlide.style.height = `${currentHeight}px`;

            // If the next active slide is on the the right side of the current active slide
            const nextSlideIndex = Array.from(nextSlide.parentNode.children).indexOf((nextSlide));
            const currentSlideIndex = Array.from(currentSlide.parentNode.children).indexOf((currentSlide));
            const nextIsOnTheRight = (nextSlideIndex > currentSlideIndex) ? true : false;

            // ...

            // On slide animation OUT ends
            currentSlide.addEventListener('transitionend', self.onSlideAnimationOutEnds, false);

            // Add 'left'/'right'
            currentSlide.classList.add(nextIsOnTheRight ? 'left' : 'right');

            // Add 'animate-out'
            currentSlide.classList.add('animate-out');

            // ...

            // On slide animation IN ends
            nextSlide.addEventListener('transitionend', self.onSlideAnimationInEnds, false);

            // Add 'next'
            nextSlide.classList.add('next');

            // Add 'left'/'right'
            nextSlide.classList.add(nextIsOnTheRight ? 'left' : 'right');
        }));
    }

    // On slide animation OUT ends
    onSlideAnimationOutEnds(e) {
        // Only execute this code for slide
        if (!e.target.parentNode.parentNode.classList.contains('tps-widget-hslider-slide')) {
            return;
        }

        // Only execute this code once
        if (e.propertyName !== 'opacity') {
            return;
        }

        // Current active slide
        const currentSlide = e.target.parentNode.parentNode;

        // Remove this event listener from the slide
        currentSlide.removeEventListener('transitionend', window.tps.horizontalSlider.onSlideAnimationOutEnds, false);

        // Remove classes
        currentSlide.classList.remove('active');
        currentSlide.classList.remove('ready');
        currentSlide.classList.remove('animate-in');
        currentSlide.classList.remove('animate-out');
        currentSlide.classList.remove('left');
        currentSlide.classList.remove('right');

        // Trigger next slide
        const nextSlide = currentSlide.parentNode.querySelector('.next');
        nextSlide.classList.remove('next');
        nextSlide.classList.add('active');
    }

    // On slide animation IN ends
    onSlideAnimationInEnds(e) {
        // Only execute this code for slide
        if (!e.target.classList.contains('tps-widget-hslider-slide')) {
            return;
        }

        // Only execute this code once
        if (e.propertyName !== 'flex-grow' && e.propertyName !== 'flex' && e.propertyName !== 'max-height') {
            return;
        }

        // Next active slide
        const nextSlide = e.target;

        // Remove this event listener from the slide
        nextSlide.removeEventListener('transitionend', window.tps.horizontalSlider.onSlideAnimationInEnds, false);

        // Add 'ready'
        nextSlide.classList.add('ready');

        setTimeout(() => {
            // Add 'animate-in'
            nextSlide.classList.add('animate-in');

            // Remove classes
            nextSlide.classList.remove('left');
            nextSlide.classList.remove('right');
        }, 10);

        // Clear height
        nextSlide.style.height = '';

        // Scroll to the slide on mobile
        if (window.innerWidth <= 767) { // small than tablet
            const headerHeight = tps.header.header.offsetHeight;
            const topScroll = nextSlide.getBoundingClientRect().top + window.scrollY - headerHeight;

            if (tps.polyfill.supportsNativeSmoothScroll) {
                window.scrollTo({
                    top: topScroll,
                    left: 0,
                    behavior: 'smooth'
                });
            } else {
                tps.polyfill.smoothScrollTo(topScroll, 500);
            }
        }
    }

    // Add "animated" class to a title on scroll
    addEffectOnScroll() {
        const self = this;

        // On event on scroll
        window.addEventListener('scroll', e => {
            if (!self.done && !self.ticking) {
                window.requestAnimationFrame(function () {
                    // Add or remove "animated" class based on scroll position
                    self._applyAnimatedClass(window.scrollY, window.innerHeight);

                    // Avoid window to overload with the event trigger
                    self.ticking = false;
                });

                // Avoid window to overload with the event trigger
                self.ticking = true;
            }
        });

        // Add or remove "animated" class based on scroll position
        self._applyAnimatedClass(window.scrollY, window.innerHeight);
    }

    // Helper: add or remove "animated" class based on scroll position
    _applyAnimatedClass(scrollY, windowHeight) {
        // Widgets that still don't have the "animated" class
        const widgets = [...this.widgets].filter(widget => widget.classList.contains('tps-widget-animated') === false);

        if (widgets.length > 0) {
            // Middle of the window (vertical)
            const middleOfWindow = scrollY + (windowHeight / 2);

            widgets.forEach((widget) => {
                const offsetTop = widget.getBoundingClientRect().top + window.scrollY;

                // If the top of the widget is positioned ahead of the middle of the window
                if (offsetTop < middleOfWindow) {
                    // Add class that triggers the animation
                    widget.classList.add('tps-widget-animated');
                }
            });
        } else {
            // When all the widget were animated
            this.done = true;
        }
    }
}
