export default class Services {

    // Constructor
    constructor() {
        // All blocks
        this.blocks = null;

        // Avoid window to overload with the event trigger
        this.ticking = false;

        // When all the blocks were animated
        this.done = false;
    }

    // Init before frameworks
    init() {
        // All blocks
        this.blocks = document.querySelectorAll('.tps-svg-service');

        if (this.blocks.length > 0) {
            // Set animation based on scroll position
            this.addEffectOnScroll();
        }
    }

    // Init after frameworks
    initAfterFrameworks() { }

    // Set animation based on scroll position
    addEffectOnScroll() {
        const self = this;

        // Blocks that still don't have the "animated" class
        const blocks = [...this.blocks].filter(block => block && block.offsetParent !== null && block.classList.contains('tps-svg-service-animated') === false);

        if (blocks.length > 0) {
            blocks.forEach((block) => {
                const json = block.dataset.animation;

                if (json) {
                    const name = json.replace(/[^a-zA-Z0-9]/gi, '');

                    // Load animation
                    lottie.loadAnimation({
                        name: name,
                        container: block,
                        renderer: 'svg',
                        loop: false,
                        autoplay: false,
                        path: json
                    });
                }
            });
        }

        // On event on scroll
        window.addEventListener('scroll', e => {
            if (!self.done && !self.ticking) {
                window.requestAnimationFrame(function () {
                    // Set animation based on scroll position
                    self._startAnimation(window.scrollY, window.innerHeight);

                    // Avoid window to overload with the event trigger
                    self.ticking = false;
                });

                // Avoid window to overload with the event trigger
                self.ticking = true;
            }
        });

        // Set animation based on scroll position
        self._startAnimation(window.scrollY, window.innerHeight);
    }

    // Helper: add or remove "animated" class based on scroll position
    _startAnimation(scrollY, windowHeight) {
        const self = this;

        // Blocks that still don't have the "animated" class
        const blocks = [...this.blocks].filter(block => block && block.offsetParent !== null && block.classList.contains('tps-svg-service-animated') === false);

        if (blocks.length > 0) {
            // Middle of the window (vertical)
            const middleOfWindow = scrollY + (windowHeight / 2);

            blocks.forEach((block) => {
                const offsetTop = block.getBoundingClientRect().top + window.scrollY;

                // If the top of the block is positioned ahead of the middle of the window
                if (offsetTop < middleOfWindow) {
                    const json = block.dataset.animation;

                    if (json) {
                        const name = json.replace(/[^a-zA-Z0-9]/gi, '');
                        block.classList.add('tps-svg-service-animated');

                        // Start animation
                        lottie.play(name);
                    }
                }
            });
        } else {
            // When all the blocks were animated
            this.done = true;
        }
    }

}
